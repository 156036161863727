const TRANSACTION_TYPE = {
  TRANSACTION_TYPE_SUBSCRIPTION: "subscription",
  TRANSACTION_TYPE_BUY_TRIAL: "buy_trial",
  TRANSACTION_TYPE_SUBSCRIPTION_RENEW: "subscription_renew",
  TRANSACTION_TYPE_SUBSCRIPTION_MODIFY: "subscription_modify",
  TRANSACTION_TYPE_TOPUP: "topup"
}

const TRANSACTION_PAYMENT_TYPE = {
  TRANSACTION_PAYMENT_TYPE_CYRPTOCURRENCY: "cryptocurrency",
  TRANSACTION_PAYMENT_TYPE_ACCOUNT_BALANCE: "account_balance"
}

const TRANSACTION_STATUS = {
  TRANSACTION_STATUS_PAYMENT_SUCCESS: 100,
  TRANSACTION_STATUS_CONFIRMED_COIN_RECEPTION: 1,
  TRANSACTION_STATUS_WAITING_FUND: 0,
  TRANSACTION_STATUS_CANCELED: -1
}

const Transaction = {
  TRANSACTION_PAYMENT_TYPE: TRANSACTION_PAYMENT_TYPE,
  TRANSACTION_STATUS: TRANSACTION_STATUS,
  TRANSACTION_TYPE: TRANSACTION_TYPE
}

export default Transaction;
